<template>
	<svg width="369" height="79" viewBox="0 0 369 79" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_44_9925)">
			<path
				d="M70.1961 0.163086H8.67818C3.99677 0.163086 0.199219 3.97807 0.199219 8.68093V70.481C0.199219 75.1838 3.99677 78.9988 8.67818 78.9988H70.1961C74.8775 78.9988 78.6751 75.1838 78.6751 70.481V8.68093C78.6751 3.97807 74.8775 0.163086 70.1961 0.163086Z"
				fill="white" />
			<path
				d="M44.3328 20.3555H54.0678V36.8601C50.9299 38.2026 47.6779 39.1584 44.3328 39.7149V20.3555ZM23.7718 20.3555H33.5617V40.1652C30.2124 39.8933 26.9266 39.2306 23.7718 38.2109V20.3555ZM33.5574 58.8068H23.7676V44.524C26.9436 45.5478 30.2124 46.2021 33.5574 46.4867V58.8068ZM54.0635 58.8068H44.3286V46.1256C47.6864 45.6031 50.951 44.7067 54.0635 43.4576V58.8111V58.8068Z"
				fill="#016A6D" />
			<path
				d="M53.9945 40.8257C50.9158 42.1342 47.6553 43.0858 44.2596 43.638C42.0183 44.0034 39.7136 44.1946 37.3707 44.1946C36.0597 44.1946 34.7657 44.1351 33.4885 44.0203C30.097 43.7145 26.8195 43.0093 23.6986 41.9514V40.7025C26.8322 41.671 30.1055 42.304 33.4885 42.5632C34.6641 42.6567 35.8483 42.7035 37.0451 42.7035C39.4978 42.7035 41.9083 42.508 44.2596 42.1299C47.6427 41.5904 50.9032 40.6728 53.9945 39.4237C56.0287 38.6038 57.995 37.6394 59.8727 36.5392C60.0249 36.8578 60.1603 37.1891 60.2702 37.5248C58.2826 38.7907 56.1852 39.8995 53.9945 40.83V40.8257ZM62.7441 37.2782C62.6638 38.0898 62.4735 38.9054 62.1606 39.6998C62.0591 39.9547 61.9448 40.2054 61.8264 40.4348C61.9111 39.1943 61.7292 37.9666 61.3147 36.8281C61.2048 36.5135 61.0695 36.2077 60.9215 35.9062C60.304 34.6699 59.3906 33.5738 58.2404 32.7368C58.6845 32.8431 59.1285 32.9748 59.5598 33.1489C60.2026 33.4081 60.7946 33.7267 61.336 34.1091C61.7038 34.3682 62.0041 34.6954 62.2324 35.0607C62.4016 35.3241 62.5285 35.6087 62.6172 35.9062C62.7484 36.3437 62.795 36.8067 62.7526 37.2741L62.7441 37.2782Z"
				fill="#FF7617" />
			<path
				d="M237.955 18.1089C234.957 18.1089 232.421 20.5978 232.421 23.61C232.421 26.623 234.957 29.1702 237.955 29.1702C240.954 29.1702 243.491 26.623 243.491 23.61C243.491 20.5978 240.954 18.1089 237.955 18.1089Z"
				fill="white" />
			<path d="M242.74 60.5108V31.5474H233.17V60.5108H242.74Z" fill="white" />
			<path
				d="M131.35 19.8994V60.4481H121.084V44.8632H109.723V60.4481H99.4027V19.8994H109.723V34.902H121.084V19.8994H131.35Z"
				fill="white" />
			<path fill-rule="evenodd" clip-rule="evenodd"
				d="M135.107 46.0269C135.107 37.3955 141.971 30.7354 150.504 30.7354C159.037 30.7354 165.9 37.3955 165.9 46.0269C165.9 54.6582 159.096 61.3185 150.504 61.3185C141.912 61.3185 135.107 54.6582 135.107 46.0269ZM156.326 46.0269C156.326 42.4326 153.848 40.0027 150.504 40.0027C147.159 40.0027 144.736 42.437 144.736 46.0269C144.736 49.616 147.213 52.0512 150.504 52.0512C153.794 52.0512 156.326 49.616 156.326 46.0269Z"
				fill="white" />
			<path
				d="M180.88 61.3228C186.764 61.3228 193.337 58.8339 193.337 51.5915C193.337 44.4301 187.291 43.0298 183.061 42.0503C180.785 41.5227 179.034 41.1177 179.034 40.0027C179.034 39.1368 179.842 38.6137 181.166 38.6137C181.999 38.6137 182.708 38.8426 183.31 39.2866C183.879 39.7049 184.351 40.3144 184.744 41.1026L192.815 37.4546C190.454 32.7056 186.127 30.7354 180.937 30.7354C175.057 30.7354 169.348 33.9193 169.348 40.4668C169.348 47.1173 175.264 48.6506 179.424 49.7287C181.678 50.3126 183.416 50.7628 183.416 51.8213C183.416 52.8632 182.496 53.3865 180.821 53.3865C178.572 53.3865 177.073 52.3401 176.324 50.1981L167.961 54.4275C170.268 59.3526 174.995 61.3228 180.88 61.3228Z"
				fill="white" />
			<path fill-rule="evenodd" clip-rule="evenodd"
				d="M229.019 46.0269C229.019 54.7164 223.192 61.3186 215.467 61.3186C211.777 61.3186 209.123 60.0423 207.279 57.8413V72.0918H197.708V31.543H207.279V34.2081C209.123 32.0073 211.777 30.731 215.467 30.731C223.192 30.731 229.019 37.3376 229.019 46.0269ZM219.389 46.0269C219.389 42.2617 216.912 39.7138 213.333 39.7138C209.756 39.7138 207.279 42.2617 207.279 46.0269C207.279 49.7921 209.756 52.3402 213.333 52.3402C216.912 52.3402 219.389 49.7921 219.389 46.0269Z"
				fill="white" />
			<path
				d="M260.607 40.7554V49.1558C260.607 51.8801 262.739 52.0509 266.604 51.821V60.5113C254.839 61.9584 251.031 58.3103 251.031 49.391V40.7597H246.708V31.5471H251.031V26.3339L260.602 23.439V31.5471H266.599V40.7597L260.607 40.7554Z"
				fill="white" />
			<path fill-rule="evenodd" clip-rule="evenodd"
				d="M300.906 60.5108V31.5465L291.337 31.5518V34.2169C289.491 32.0159 286.839 30.7397 283.149 30.7397C275.424 30.7397 269.597 37.3417 269.597 46.0313C269.597 54.7208 275.424 61.3228 283.149 61.3228C286.839 61.3228 289.491 60.0466 291.337 57.8456V60.5108H300.906ZM285.276 39.7137C288.855 39.7137 291.332 42.2616 291.332 46.0268C291.332 49.792 288.855 52.3401 285.276 52.3401C281.699 52.3401 279.222 49.792 279.222 46.0268C279.222 42.2616 281.699 39.7137 285.276 39.7137Z"
				fill="white" />
			<path d="M305.992 18.2217H315.563V60.5108H305.992V18.2217Z" fill="white" />
			<path
				d="M325.887 18.1089C322.889 18.1089 320.353 20.5978 320.353 23.61C320.353 26.623 322.889 29.1702 325.887 29.1702C328.886 29.1702 331.422 26.623 331.422 23.61C331.422 20.5978 328.886 18.1089 325.887 18.1089Z"
				fill="white" />
			<path d="M330.673 60.5108V31.5474H321.102V60.5108H330.673Z" fill="white" />
			<path
				d="M348.09 40.7554V49.1558C348.09 51.8801 350.222 52.0509 354.086 51.821V60.5113C342.322 61.9584 338.514 58.3103 338.514 49.391V40.7597H334.191V31.5471H338.514V26.3339L348.085 23.439V31.5471H354.082V40.7597L348.09 40.7554Z"
				fill="white" />
			<path
				d="M363.311 18.1089C360.312 18.1089 357.776 20.5978 357.776 23.61C357.776 26.623 360.312 29.1702 363.311 29.1702C366.309 29.1702 368.846 26.623 368.846 23.61C368.846 20.5978 366.309 18.1089 363.311 18.1089Z"
				fill="white" />
			<path d="M368.096 60.5108V31.5474H358.525V60.5108H368.096Z" fill="white" />
		</g>
		<defs>
			<clipPath id="clip0_44_9925">
				<rect width="369" height="79" fill="white" />
			</clipPath>
		</defs>
	</svg>


</template>
